<template>
  <div class="comment-filter">
    <div class="checkbox-content">
      <div class="interaction-checkbox d-flex align-items-center">
        <div class="title-interaction">リアクション</div>
        <div class="list-checkbox-interaction">
          <div
              class="intention-item-checkbox"
              v-if="videoDetail.interaction_btn_1"
          >
            <icon-checkbox class="icon icon-checkbox"
                           data-cdai="action"
                           :color="filterDataLocal.event_type.includes(evenTypeComment.btn1) ?
                            colorInteraction[videoDetail.interaction_btn_1.color].color :
                            '#999999'"
                           @click.native="updateCheckedInteraction(evenTypeComment.btn1)">
            </icon-checkbox>
            <span
                class="intention-item-name"
                v-html="videoDetail.interaction_btn_1.text"
                :style="{ color: filterDataLocal.event_type.includes(evenTypeComment.btn1) ?
                            colorInteraction[videoDetail.interaction_btn_1.color].color :
                            '#999999' }"
            >
          </span>
          </div>
          <div
              class="intention-item-checkbox"
              v-if="Object.keys(videoDetail.interaction_btn_2).length > 0"
          >
            <icon-checkbox class="icon icon-checkbox"
                           data-cdai="action"
                           :color="filterDataLocal.event_type.includes(evenTypeComment.btn2) ?
                            colorInteraction[videoDetail.interaction_btn_2.color].color :
                            '#999999'"
                           @click.native="updateCheckedInteraction(evenTypeComment.btn2)"></icon-checkbox>
            <span
                class="intention-item-name"
                v-html="videoDetail.interaction_btn_2.text"
                :style="{ color: filterDataLocal.event_type.includes(evenTypeComment.btn2) ?
                            colorInteraction[videoDetail.interaction_btn_2.color].color :
                            '#999999' }"
            >
          </span>
          </div>
          <div
              class="intention-item-checkbox"
          >
            <icon-checkbox class="icon icon-checkbox"
                           data-cdai="action"
                           :color="filterDataLocal.no_reaction_flg ? '#49AB94' :'#999999'"
                           @click.native="updateCheckedNoReactionFlg"></icon-checkbox>
            <span
                class="intention-item-name"
                :style="{ color: filterDataLocal.no_reaction_flg ? '#49AB94' :'#999999' }"
            >
            リアクション取得外のコメント表示
          </span>
          </div>
        </div>
      </div>
      <div class="intention-checkbox d-flex align-items-center mt-2" v-if="filterData.intention_id">
        <div class="intention-select d-flex">
          <div class="select"
               :class="{ 'select-open': selectClickFlg }"
               @click="selectClickFlg = !selectClickFlg"
               data-cdai="action"
          >
            <p class="m-0" :title="selectedIntention.name">{{ selectedIntention.name }}</p>
          </div>
          <div v-if="selectClickFlg" class="intention-option" @click="selectClickFlg = !selectClickFlg" data-cdai="action">
            <div v-for="item in filterDataLocal.intentions"
                 :key="item.id" class="select-option"
                 data-cdai="action"
                 :class="{'intention-selected': item.id == filterDataLocal.intention_id}"
                 @click="selectIntention(item.id)"
                 @mouseover="addOptionColor(item.id)">
              <p class="m-0" :title="item.name">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="list-level-intention d-flex align-items-center flex-wrap">
          <div class="intention-item-checkbox" v-for="item in intentionLevels" :key="'intention-level-' + item.level">
            <icon-checkbox class="icon icon-checkbox"
                           data-cdai="action"
                           :color="filterDataLocal.level.includes(item.level) ?'#49AB94':'#999999'"
                           @click.native="updateCheckedLevel(item.level)">
            </icon-checkbox>
            <span
                class="intention-item-name"
                :style="{ color: filterDataLocal.level.includes(item.level) ?'#49AB94':'#999999'}"
            >
            {{ item.value }}
          </span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-content">
      <button class="btn-submit"
              data-cdai="action"
              @click="submitFilter"
              :class="{
                 'disabled': !isEnableSubmit,
              }"><span>絞り込む</span>
      </button>
    </div>
  </div>
</template>
<script>

import { colorInteraction, evenTypeComment } from "@/commons/constants";
import IconCheckbox from "@/components/Commons/IconCheckbox.vue";
import EventBus from "@/commons/event_bus";

export default {
  components: { IconCheckbox },
  props: [
    'filterData',
    'videoDetail',
  ],
  data() {
    return {
      selectClickFlg: false,
      colorInteraction: colorInteraction,
      intentionLevels: this.filterData.intentionLevels,
      filterDataLocal: JSON.parse(JSON.stringify(this.filterData)),
      evenTypeComment: evenTypeComment,
    }
  },
  computed: {
    selectedIntention() {
      return this.filterDataLocal.intentions.find(item => item.id == this.filterDataLocal.intention_id)
    },
    isEnableSubmit() {
      if (this.filterData && Object.keys(this.filterData).length) {
        return JSON.stringify(this.filterData) != JSON.stringify(this.filterDataLocal)
      }
      return false
    }
  },
  watch: {
    filterData() {
      this.filterDataLocal = JSON.parse(JSON.stringify(this.filterData))
    },
  },
  methods: {
    selectIntention(id) {
      this.filterDataLocal.intention_id = id;
    },
    updateCheckedInteraction(event) {
      if (this.filterDataLocal.event_type.includes(event)) {
        this.filterDataLocal.event_type = this.filterDataLocal.event_type.filter(item => item != event)
      } else {
        this.filterDataLocal.event_type.push(event)
      }
      this.filterDataLocal.event_type.sort((a, b) => a - b)
    },
    updateCheckedLevel(level) {
      if (this.filterDataLocal.level.includes(level)) {
        this.filterDataLocal.level = this.filterDataLocal.level.filter(item => item != level)
      } else {
        this.filterDataLocal.level.push(level)
      }
      this.filterDataLocal.level.sort((a, b) => a - b)
    },
    updateCheckedNoReactionFlg() {
      this.filterDataLocal.no_reaction_flg = this.filterDataLocal.no_reaction_flg == 0 ? 1 : 0
    },
    clickSelect() {
      this.selectClickFlg = !this.selectClickFlg;
    },
    addOptionColor(intention_id) {
      let target = document.getElementsByClassName('intention-selected');
      if (intention_id != this.filterDataLocal.intention_id) {
        target[0].style.background = '#F0F0F0';
      } else {
        target[0].style.background = '#EDF7F4';
      }
    },
    windowClick() {
      document.addEventListener('click', (e) => {
        if (!e.target.closest('.intention-select')) {
          this.selectClickFlg = false;
        }
      })
    },
    submitFilter() {
      if (!this.isEnableSubmit) return
      EventBus.$emit('updateFilterComment', this.filterDataLocal)
    },
    addCheckboxCustomData() {
      const setCustomData = (selector, dataAttribute, value) => {
        const elements = this.$el.querySelectorAll(selector);
        if(elements){
          elements.forEach(element => element.setAttribute(dataAttribute, value));
        }
      };

      setCustomData('.interaction-checkbox .icon-checkbox svg', 'data-cdai', 'action');
      setCustomData('.intention-item-checkbox .icon-checkbox svg', 'data-cdai', 'action');
    }
  },
  created() {

  },
  mounted() {
    this.windowClick();
    this.addCheckboxCustomData();
  },
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variable.scss";

.comment-filter {
  padding: 25px 20px;
  box-sizing: border-box;
  border: 1px solid #EBEBEB;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  .interaction-checkbox {
    .title-interaction {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      margin-right: 40px;
      margin-left: 10px;
    }
  }

  .intention-item-checkbox {
    margin-right: 20px;
    display: inline-flex;
    align-items: center;

    .icon-checkbox {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    .intention-item-name {
      font-size: 12px;
      line-height: 24px;
      color: #333333;
      margin-top: 4px;
      user-select: none;
    }
  }

  .intention-select {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 20px;

    .select {
      border: solid 1px transparent;
      border-radius: 8px;
      display: flex;
      align-items: center;
      height: 30px;
      width: 80px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url(/images/svg/icon_select_dark_arrow.svg);
      background-size: 36px 14px;
      background-repeat: no-repeat;
      background-position: center right;
      padding: 0 10px;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        width: 70px;
        font-weight: 700;
      }
    }

    .select-open {
      background-image: url(/images/svg/icon_select_dark_arrow_up.svg);
      border: solid 1px transparent;
    }

    .intention-option {
      z-index: 6;
      user-select: none;
      border: solid 1px #DDDDDD;
      border-radius: 8px;
      overflow: hidden;
      width: 100px;
      padding-top: 30px;
      position: absolute;
      top: 0;

      .select-option {
        cursor: pointer;
        display: flex;
        width: 100px;
        height: 30px;
        padding: 0 5px;
        border-bottom: 1px solid #DDDDDD;
        align-items: center;
        background-color: #fff;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          width: 80px;
        }

        &.intention-selected {
          background-color: #EDF7F4;
        }

        &:hover {
          background-color: #EDF7F4;
        }
      }

      .select-option:last-child {
        border-bottom: none;
      }
    }
  }
}

.button-content {
  margin-left: auto;
}

.btn-submit {
  font-weight: 700;
  font-size: 15px;
  font-family: 'Noto Sans JP';
  line-height: 23px;
  border-radius: 10px;
  padding: 10px 20px;
  background: $green;
  color: $white;
  cursor: pointer;
  user-select: none;
  border: none;

  &.disabled {
    background-color: $middle-black;
    cursor: not-allowed;
  }
}

</style>