<template>
  <div id="chart-intention-section">
    <div class="chart-report-intention" id="chart-report-intention">
      <div class="title">意向度別</div>
      <div class="video-report-area mt-4 mb-15">
        <div class="row-3">
          <div class="row-3-col-1">
            <div class="card video-list">
              <div class="body">
                <div class="tabs">
                  <div
                    class="tab-item"
                    :class="{ active: typeChart }"
                    @click="changeTypeChart(true)"
                    data-report="action"
                  >
                    総リアクション
                  </div>
                  <div class="tab-split"></div>
                  <div
                    class="tab-item"
                    :class="{ active: !typeChart }"
                    @click="changeTypeChart(false)"
                    data-report="action"
                  >
                    各リアクション
                  </div>
                </div>
                <div class="group-tag-list">
                  <div class="d-flex flex-wrap">
                    <template v-for="intention in videoIntention">
                      <div
                        class="tag tag-two"
                        :key="'intention-' + intention.id"
                        :class="{ active: videoAttributeId == intention.id }"
                        data-report="action"
                        @click="selectIntention(intention.id)"
                      >
                        <div>{{ intention.name }}</div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="list-checkbox">
                  <template v-for="level in intentionLevels">
                    <div
                      class="list-item item-compare"
                      :class="{ 'active selected': selectedSeriesId == level.level }"
                      :key="'attribute-' + level.level"
                      :id="'attrValue-' + level.level"
                    >
                      <!--check click all-->
                      <div class="item" v-if="level.level == 0">
                        <i
                          data-report="action"
                          class="icon icon-checkbox"
                          :class="intentionLevelSelected.includes(level.level) ? 'checked' : ''"
                          @click="checkedAttributeValue(level.level)"
                        ></i>
                        <div class="item-name pl-2" @click="selectAttributeValue(level)">
                          <div class="name">全体</div>
                          <div class="percent">
                            <span v-if="typeChart">
                              <span>平均: </span>
                              {{ reportSummary.tia.total }}
                              {{ `（${reportSummary.tia_percent.total}%）` }}
                            </span>
                            <span v-else :style="colorTextBtnLeft">
                              <span :style="colorCircleBtnLeft"></span>
                              <span>平均: </span>
                              {{ reportSummary.tia.left }}
                              {{ `（${reportSummary.tia_percent.left}%）` }}</span
                            >
                          </div>
                          <div class="percent mt-1">
                            <span v-if="typeChart">
                              <span>人数: </span>
                              <span>{{ reportSummary.user_count }}</span>
                            </span>
                            <span v-else :style="colorTextBtnRight">
                              <span :style="colorCircleBtnRight"></span>
                              <span>平均: </span>
                              {{ typeChart ? reportSummary.tia.total : reportSummary.tia.right }}
                              {{
                                `（${typeChart ? reportSummary.tia_percent.total : reportSummary.tia_percent.right}%）`
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <!--end check click all-->
                      <div class="item" v-else>
                        <i
                          class="icon icon-checkbox"
                          :class="intentionLevelSelected.includes(level.level) ? 'checked' : ''"
                          data-report="action"
                          @click="checkedAttributeValue(level.level)"
                        ></i>
                        <div class="item-name pl-2" @click="selectAttributeValue(level)" data-report="action">
                          <div class="name">{{ level.value }}</div>
                          <div class="percent">
                            <span v-if="typeChart">
                              <span>平均: </span>
                              <span>
                                {{ level.tiaTotal || 0 }}
                                {{ `（${level.tiaPercentTotal || 0}%）` }}
                              </span>
                            </span>
                            <span :style="colorTextBtnLeft" v-else>
                              <span :style="colorCircleBtnLeft"></span>
                              <span>平均: </span>
                              <span>
                                {{ level.tiaLeft || 0 }}
                                {{ `（${level.tiaPercentLeft || 0}%）` }}
                              </span>
                            </span>
                          </div>
                          <div class="percent mt-1">
                            <span v-if="typeChart">
                              <span>人数: </span>
                              <span>
                                {{ level.user_count }}
                                {{
                                  `（${roundDecimal(
                                    totalCountAttrValue > 0 ? level.user_count / reportSummary.user_count : 0,
                                  )}%）`
                                }}</span
                              >
                            </span>
                            <span v-else :style="colorTextBtnRight">
                              <span :style="colorCircleBtnRight"></span>
                              <span>平均: </span>
                              <span>
                                {{ level.tiaRight || 0 }}
                                {{ `（${level.tiaPerCentRight || 0}%）` }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <intention-chart
            :reportSummary="reportSummary"
            :videoIntention="videoIntention"
            :selectedSeriesId="selectedSeriesId"
            :seriesData="seriesData"
            :areaRangeLineSeriesData="normalSeriesData"
            :intentionLevelSelected="intentionLevelSelected"
            :videoImage="videoImage"
            :videoDetail="videoDetail"
            :typeChart="typeChart"
            :videoAttributeId="videoAttributeId"
            :chartMode="chartMode"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            @changeChartMode="changeChartMode"
          ></intention-chart>
        </div>
      </div>
    </div>
    <text-box
      :videoId="videoDetail.id"
      :urlGet="urlApiGetVideoIntentionTabular"
      :urlPost="urlApiPostVideoIntentionTabular"
      :placeholder="placeHolderTextBox"
      :classTextBox="classTextBox"
      :idTextBox="'intention-text-box'"
      :dataCustom="'data-report'"
    >
    </text-box>
    <div class="group-list-segment mt-8 mb-18">
      <div class="mt-3">
        <div class="row-3 mt-5" v-if="intentionRankSelected">
          <div class="row-3-col-1 mb-3 max-capture-cut" v-for="(rank, key) in intentionRankSelected.good" :key="key">
            <div
              class="card card-segment card-subtitle"
              :class="{ inactive: rank.interaction_count == null || rank.event_at == null }"
            >
              <div class="content">
                <div class="segment-title">
                  <div class="reaction-type mb-3">
                    <img style="margin-right: 8px; width: 24px" :src="interactionIcon.rank_2" alt="" />
                    {{ interactionText.rank_2 }}の最大値カット
                  </div>
                  <div class="title-right">
                    <div class="name">{{ rank.levelName }}</div>
                  </div>
                </div>
                <div class="segment-body" v-if="rank.interaction_count != null || rank.event_at != null">
                  <div class="body-left">
                    <img class="card-image" :src="videoImage.image_url + rank.event_at + '.jpg'" />
                  </div>
                  <div class="body-right">
                    <div class="data">{{ rank.interaction_count }}%</div>
                    <div class="value">{{ rank.event_at }}s</div>
                  </div>
                </div>
                <div class="segment-body" v-else>
                  <div class="message">このセグメントの反応値はございません。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-3 mt-5" v-if="intentionRankSelected">
          <div class="row-3-col-1 mb-3 max-capture-cut" v-for="(rank, key) in intentionRankSelected.bad" :key="key">
            <div
              class="card card-segment card-subtitle"
              :class="{ inactive: rank.interaction_count == null || rank.event_at == null }"
            >
              <div class="content">
                <div class="segment-title">
                  <div class="reaction-type mb-3">
                    <img style="margin-right: 8px; width: 24px" :src="interactionIcon.rank_3" alt="" />
                    <div>{{ interactionText.rank_3 }}の最大値カット</div>
                  </div>
                  <div class="title-right">
                    <div class="name">{{ rank.levelName }}</div>
                  </div>
                </div>
                <div class="segment-body" v-if="rank.interaction_count != null || rank.event_at != null">
                  <div class="body-left">
                    <img class="card-image" :src="videoImage.image_url + rank.event_at + '.jpg'" />
                  </div>
                  <div class="body-right">
                    <div class="data">{{ rank.interaction_count }}%</div>
                    <div class="value">{{ rank.event_at }}s</div>
                  </div>
                </div>
                <div class="segment-body" v-else>
                  <div class="message">このセグメントの反応値はございません。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { roundDecimal } from '@/commons/helpers'
import { getReportIntentionRanks, getReportIntentionSecond, getVideoIntention } from '@/commons/api_service'
import IntentionChart from '@/components/Charts/Intention/IntentionChart.vue'
import TextBox from '@/components/TopDetail/TextBox.vue'
import EventBus from '@/commons/event_bus'
import { intentionLevels, listChartMode } from '@/commons/constants'
import jQuery from 'jquery'

export default {
  name: 'ReportIntention',
  components: { IntentionChart, TextBox },
  props: ['videoDetail', 'videoImage', 'reportSummary'],
  data() {
    return {
      attributes: [],
      videoSegments: [],
      videoAttributes: {},
      intentionIdSelected: null,
      totalCountAttrValue: 0,
      rank_good: [],
      rank_bad: [],
      reportSegments: [],
      selectedSeriesId: null,
      seriesData: [],
      normalSeriesData: [],
      typeChart: true,
      placeHolderTextBox: 'テキストが入力できます',
      videoIntention: {},
      allIntentionRanks: null,
      intentionRanks: {},
      intentionRankSelected: {
        good: [],
        bad: [],
      },
      reportIntentionSecond: {},
      reportIntentionAll: {},
      allLevel: '1,2,3,4,5',
      chartMode: listChartMode.line.value,
      listChartMode: listChartMode,
      intentionLevels: [],
      intentionLevelSelected: [intentionLevels[0].level],
      defaultLevel: intentionLevels[0].level,
      urlApiGetVideoIntentionTabular: 'api.video.intention-tabular',
      urlApiPostVideoIntentionTabular: 'api.video.intention-tabular.insert',
      classTextBox: 'intention-tabular',
    }
  },

  computed: {
    videoAttributeId() {
      return this.intentionIdSelected
    },

    colorTextBtnLeft() {
      return {
        color: this.$store.getters.interactioncolors[0] ? this.$store.getters.interactioncolors[0] : '',
      }
    },
    colorTextBtnRight() {
      return {
        color: this.$store.getters.interactioncolors[1] ? this.$store.getters.interactioncolors[1] : '',
      }
    },
    colorCircleBtnLeft() {
      return {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        marginTop: '2px',
        marginRight: '2px',
        border: '1px solid',
        borderColor: this.$store.getters.interactioncolors[0] ? this.$store.getters.interactioncolors[0] : '',
        borderRadius: '50%',
        background: this.$store.getters.interactioncolors[0] ? this.$store.getters.interactioncolors[0] : '',
      }
    },
    colorCircleBtnRight() {
      return {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        marginTop: '2px',
        marginRight: '2px',
        border: '1px solid #ce6060',
        borderColor: this.$store.getters.interactioncolors[1] ? this.$store.getters.interactioncolors[1] : '',
        borderRadius: '50%',
        background: this.$store.getters.interactioncolors[1] ? this.$store.getters.interactioncolors[1] : '',
      }
    },

    interactionIcon() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.btn_icon_url
            : '',
        rank_3:
          this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.btn_icon_url
            : '',
      }
    },
    interactionText() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.text
            : '',
        rank_3:
          this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.text
            : '',
      }
    },
    interactionColor() {
      let color_rank_2 =
        this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
          ? this.videoDetail.interaction_btn_1.color
          : ''
      let color_rank_3 =
        this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
          ? this.videoDetail.interaction_btn_2.color
          : ''
      return {
        rank_2:
          color_rank_2 == 1
            ? 'good'
            : color_rank_2 == 2
            ? 'nattoku'
            : color_rank_2 == 3
            ? 'bad'
            : color_rank_2 == 4
            ? 'suki'
            : '',
        rank_3:
          color_rank_3 == 1
            ? 'good'
            : color_rank_3 == 2
            ? 'nattoku'
            : color_rank_3 == 3
            ? 'bad'
            : color_rank_3 == 4
            ? 'suki'
            : '',
      }
    },
  },
  watch: {},
  mounted() {
    this.initDataIntention()
  },
  methods: {
    formatDataIntentionAllLevelByIntentionId(data) {
      this.intentionLevels = this.intentionLevels.map(item => {
        if (item.level && item.level !== 0) {
          let nameLevel = `level_${item?.level}`
          let findTiaByLevel = data[0]?.items[0]?.tia[nameLevel]
          let findTiaPercentByLevel = data[0]?.items[0]?.tia_percent[nameLevel]

          let itemFormat = {}
          itemFormat['level'] = item.level
          itemFormat['user_count'] = item.user_count
          itemFormat['value'] = item.value

          return {
            ...itemFormat,
            tiaTotal: findTiaByLevel?.total,
            tiaPercentTotal: findTiaPercentByLevel?.total,
            tiaLeft: findTiaByLevel?.left,
            tiaPercentLeft: findTiaPercentByLevel?.left,
            tiaRight: findTiaByLevel?.right,
            tiaPerCentRight: findTiaPercentByLevel?.right,
          }
        } else {
          return item
        }
      })
    },
    selectedChart(id) {
      this.selectedAttributeValue(id)
    },
    changeTypeChart(isTotalInteraction) {
      this.typeChart = isTotalInteraction
    },
    roundDecimal(value) {
      return roundDecimal(value * 100, 1)
    },
    selectIntention(videoIntentionId) {
      this.intentionIdSelected = videoIntentionId
      this.intentionLevelSelected = [this.defaultLevel]
      this.selectedSeriesId = this.defaultLevel
      this.seriesData = []
      this.normalSeriesData = []
      this.intentionRankSelected = {
        good: [],
        bad: [],
      }

      Promise.all([
        getReportIntentionSecond({
          video_ids: this.videoDetail.id,
          intention_id: this.intentionIdSelected,
          level: this.allLevel,
          all_flg: 0,
        }),
      ]).then(data => {
        this.reportIntentionAll = data
        this.formatSeriesDataArray(data[0]?.items, this.defaultLevel)
        this.handleRankIntentionLevel()
        this.formatDataIntentionAllLevelByIntentionId(data)
      })
    },
    checkedAttributeValue(attributeValueId) {
      let index = this.intentionLevelSelected.indexOf(attributeValueId)
      if (index >= 0) {
        this.intentionLevelSelected.splice(index, 1)
        this.seriesData = this.seriesData.filter(e => e.id !== attributeValueId)
        this.normalSeriesData = this.normalSeriesData.filter(e => e.id !== attributeValueId)
        this.intentionRankSelected.good = this.intentionRankSelected.good.filter(e => e.id !== attributeValueId)
        this.intentionRankSelected.bad = this.intentionRankSelected.bad.filter(e => e.id !== attributeValueId)
        if (this.selectedSeriesId == attributeValueId) {
          this.selectedSeriesId = null
        }
      } else {
        this.intentionLevelSelected.push(attributeValueId)
        this.handleGetIntentionReportIntentionSecond(attributeValueId, false)
        this.handleGetIntentionReportIntentionSecond(this.allLevel, true)
        let attributeValue = this.intentionLevels.find(level => level.level == attributeValueId)
        let levelName = `level_${attributeValue.level}`

        this.intentionRankSelected.good.push({
          event_at: Object.prototype.hasOwnProperty.call(this.intentionRank.data[levelName]['2_top'], 'event_at')
            ? this.intentionRank.data[levelName]['2_top'].event_at
            : null,
          interaction_count: Object.prototype.hasOwnProperty.call(
            this.intentionRank.data[levelName]['2_top'],
            'interaction_count',
          )
            ? this.intentionRank.data[levelName]['2_top'].interaction_count
            : null,
          id: attributeValueId,
          levelName: attributeValue.value,
        })
        this.intentionRankSelected.good.sort(function (a, b) {
          return b.id - a.id
        })

        this.intentionRankSelected.bad.push({
          event_at: Object.prototype.hasOwnProperty.call(this.intentionRank.data[levelName]['3_top'], 'event_at')
            ? this.intentionRank.data[levelName]['3_top'].event_at
            : null,
          interaction_count: Object.prototype.hasOwnProperty.call(
            this.intentionRank.data[levelName]['3_top'],
            'interaction_count',
          )
            ? this.intentionRank.data[levelName]['3_top'].interaction_count
            : null,
          id: attributeValueId,
          levelName: attributeValue.value,
        })
        this.intentionRankSelected.bad.sort(function (a, b) {
          return b.id - a.id
        })
      }
      if (this.intentionLevelSelected.length == 0) {
        this.selectedSeriesId = null
      }
    },
    handleGetIntentionReportIntentionSecond(attributeValueId, isAllLevel) {
      Promise.all([
        getReportIntentionSecond({
          video_ids: this.videoDetail.id,
          intention_id: this.intentionIdSelected,
          level: attributeValueId,
          all_flg: 0,
        }),
      ]).then(data => {
        if (isAllLevel) {
          this.reportIntentionAll = data
          this.formatDataIntentionAllLevelByIntentionId(data)
        } else {
          this.reportIntentionSecond = data
          this.formatSeriesDataArray(data[0]?.items, attributeValueId)
        }
      })
    },
    async initDataIntention() {
      if (this.videoDetail && this.videoDetail.is_csv_downloadable) {
        Promise.all([
          getVideoIntention({ video_ids: this.videoDetail.id }),
          getReportIntentionRanks({ video_id: this.videoDetail.id, interaction_tally: 1 }),
        ]).then(data => {
          const dataIntention = data[0]?.items
          this.videoIntention = dataIntention[0]?.intentions
          if (this.videoIntention.length > 0) {
            this.intentionIdSelected = this.videoIntention[0].id
            this.selectedSeriesId = this.defaultLevel
            this.handleGetIntentionReportIntentionSecond(this.defaultLevel, false)
            this.handleGetIntentionReportIntentionSecond(this.allLevel, true)
          }
          this.allIntentionRanks = data[1]
          this.handleRankIntentionLevel()
        })
      }
    },
    formatSeriesDataArray(data, level) {
      if (level != 0) {
        const levelKey = `level_${level}_interactions`
        const dataRow = data[0][levelKey].values
        let dataSecondTotal = []
        let dataSecond = []
        for (let row in dataRow) {
          if (row === 'total_max' || row === 'left_max' || row === 'right_max') {
            continue
          }
          dataSecond.push(dataRow[row]['left'] + dataRow[row]['right'])

          dataSecondTotal.push([parseInt(row), dataRow[row]['left'], dataRow[row]['right']])
        }
        this.seriesData.push({
          id: level,
          data: dataSecond,
        })
        this.normalSeriesData.push({
          id: level,
          data: dataSecondTotal,
        })
      } else {
        let dataSecond = []
        let dataSecondTotal = []
        this.reportSummary?.tally_result.forEach(data => {
          dataSecond.push(Math.abs(data.left) + Math.abs(data.right))
          dataSecondTotal.push([data.second, Math.abs(data.left), Math.abs(data.right)])
        })

        this.seriesData.push({
          id: level,
          data: dataSecond,
        })

        this.normalSeriesData.push({
          id: level,
          data: dataSecondTotal,
        })
      }
    },
    addClassActive(data) {
      if (data) {
        jQuery('.list-item').not('.selected').removeClass('active')
        jQuery('#' + data).addClass('active')
      }
    },
    removeClassActive(data) {
      if (data) {
        jQuery('.list-item').not('.selected').removeClass('active')
      }
    },
    changeChartMode(mode) {
      if (this.chartMode == mode) return
      if (mode == listChartMode.column.value) {
        if (this.intentionLevelSelected.length > 6) {
          this.maxValueChart = 6
          return
        }
      } else {
        this.maxValueChart = 8
      }
      this.chartMode = mode
    },
    selectAttributeValue(attributeValue) {
      if (this.intentionLevelSelected.includes(attributeValue.level)) {
        if (this.selectedSeriesId == attributeValue.level) {
          this.selectedSeriesId = null
        } else {
          this.selectedSeriesId = attributeValue.level
        }
      }
    },
    selectedAttributeValue(attributeValueId) {
      if (this.intentionLevelSelected.includes(attributeValueId)) {
        this.selectedSeriesId = attributeValueId
      }
    },
    handleRankIntentionLevel() {
      this.intentionRank = this.allIntentionRanks.find(rank => rank.intention_id == this.intentionIdSelected)
      this.intentionLevels = []
      this.totalCountAttrValue = 0
      if (this.intentionRank) {
        for (let level in intentionLevels) {
          let levelId = intentionLevels[level].level
          // if (levelId == 0) {
          //   continue
          // }
          let levelName = `level_${levelId}`
          this.intentionLevels.push({
            level: parseInt(levelId),
            value: intentionLevels[level].value,
            user_count: this.intentionRank.data[levelName]?.user_count ?? 0,
          })
          this.totalCountAttrValue += this.intentionRank?.data[levelName]?.user_count ?? 0

          if (levelId == this.selectedSeriesId && levelId != 0) {
            this.intentionRankSelected.good.push({
              event_at: Object.prototype.hasOwnProperty.call(this.intentionRank.data[levelName]['2_top'], 'event_at')
                ? this.intentionRank.data[levelName]['2_top'].event_at
                : null,
              interaction_count: Object.prototype.hasOwnProperty.call(
                this.intentionRank.data[levelName]['2_top'],
                'interaction_count',
              )
                ? this.intentionRank.data[levelName]['2_top'].interaction_count
                : null,
              id: levelId,
              levelName: intentionLevels[level].value,
            })
            this.intentionRankSelected.good.sort(function (a, b) {
              return b.id - a.id
            })

            this.intentionRankSelected.bad.push({
              event_at: Object.prototype.hasOwnProperty.call(this.intentionRank.data[levelName]['3_top'], 'event_at')
                ? this.intentionRank.data[levelName]['3_top'].event_at
                : null,
              interaction_count: Object.prototype.hasOwnProperty.call(
                this.intentionRank.data[levelName]['3_top'],
                'interaction_count',
              )
                ? this.intentionRank.data[levelName]['3_top'].interaction_count
                : null,
              id: levelId,
              levelName: intentionLevels[level].value,
            })
            this.intentionRankSelected.bad.sort(function (a, b) {
              return b.id - a.id
            })
          }
        }
      }
    },
  },

  created() {
    EventBus.$on('selectedChart', this.selectedChart)
  },
  beforeDestroy() {
    EventBus.$off('selectedChart', this.selectedChart)
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variable';

.chart-report-intention {
  margin-top: 32px;

  .title {
    @include font(xxl);
    font-weight: bold;
  }

  .video-report-area {
    margin-top: 30px;
    .video-list {
      width: 100%;
      height: 353px;
      display: flex;
      flex-direction: column;
      position: relative;
      @include font(s);

      .body {
        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $black;
        }

        direction: rtl;
        overflow-y: auto;
        //padding-bottom: 8px;
      }

      //.setting {
      //  justify-content: end;
      //  max-height: 28px;
      //  font-weight: 700;
      //  font-size: 10px;
      //  line-height: 16px;
      //  color: #49AB94;
      //  padding: 6px 12px;
      //  margin-top: auto;
      //  border: 1px solid #EBEBEB;
      //  border-bottom-left-radius: 8px;
      //  border-bottom-right-radius: 8px;
      //  cursor: pointer;
      //}
      .tabs {
        direction: ltr;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 21px 20px 0;

        .tab-item {
          cursor: pointer;
          color: $middle-black;
          padding-left: 8px;
          padding-right: 8px;

          &.active {
            color: $black;
            font-weight: bold;
          }

          &:last-child {
            padding-left: 8px;
          }
        }

        .tab-split {
          width: 1px;
          background-color: $light-black;
          height: 8px;
        }
      }

      .group-tag-list {
        display: flex;
        flex-direction: column;
        direction: ltr;
        padding: 20px;
        border-top: 0;

        .tag {
          font-family: 'Noto Sans JP';
          margin-right: 10px;
          margin-top: 10px;
          height: 40px;
        }
      }

      .list-checkbox {
        display: flex;
        flex-wrap: wrap;
        direction: ltr;
        cursor: pointer;
        align-items: center;

        .list-item {
          display: flex;
          padding: 12px 20px;
          max-width: 50%;
          flex: 0 0 50%;
          box-sizing: border-box;

          &.active {
            background-color: $light-green;
          }

          .name {
            color: #333333;
            font-size: 12px;
            line-height: 19px;
          }

          .percent {
            @include font(xs);
            line-height: 8px;
            color: $middle-black;
            font-size: 9px;
          }
          .icon-circle-red {
            display: inline-block;
            width: 7px;
            height: 7px;
            margin-top: 2px;
            margin-right: 2px;
            border: 1px solid #ce6060;
            border-radius: 50%;
            background: red;
          }
          .icon-circle-blue {
            display: inline-block;
            width: 7px;
            height: 7px;
            margin-top: 2px;
            margin-right: 2px;
            border: 1px solid #4976ab;
            border-radius: 50%;
            background: #4976ab;
          }
          .percent-good {
            @include font(xs);
            color: $green;
            display: flex;
            align-items: center;

            .dot-good {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $green;
            }
          }

          .percent-bad {
            @include font(xs);
            color: $red;
            display: flex;
            align-items: center;

            .dot-bad {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $red;
            }
          }

          &.item-compare {
            justify-content: space-between;

            .item {
              width: 100%;
              display: flex;
              align-items: center;

              .item-name {
                flex: 1 1;
              }
            }
          }
        }
      }

      .answer-limited-msg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 8px;

        div {
          position: relative;
          color: #333333;
          font-size: 16px;
          line-height: 22px;
          width: 380px;
          height: 200px;
          background-color: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 8px;
        }

        i {
          position: absolute;
          cursor: pointer;
          top: 20px;
          right: 20px;
          z-index: 1;
        }
      }
    }
  }
}

.group-list-segment {
  .card-segment {
    height: 100%;

    .data {
      @include font(xxl);
      font-weight: bold;
    }
  }
}

.card.card-segment.card-subtitle.inactive {
  background-color: #ebebeb;
}
</style>
